.div-block {
	background-color: #0077c8;
}

.container.anchor-link-div {
	display: -ms-flexbox;
	display: -webkit-box;
	display: -webkit-flex;
	display: flex;
	padding: 0.75rem;
}

.anchor-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.anchor-link {
	padding: 0.25rem 1.5rem 0.25rem 0.5rem;
	border-bottom: 2px solid #0077c8;
	background-image: url('../images/chevron-right-LTteal-01.svg');
	background-position: 96% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.85rem;
	letter-spacing: 0.1rem;
	text-decoration: none;
	text-transform: uppercase;
}

.anchor-link:hover {
	border-bottom-color: hsla(0, 0%, 100%, 0.3);
	background-image: url('../images/chevron-right-white-01.svg');
	background-position: 100% 50%;
	background-size: 12px 12px;
}

.camp-title {
	color: #0060af;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 400px;
	margin-top: 0rem;
	margin-bottom: 2rem;
	padding-bottom: 1rem;
	padding-left: 1rem;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-image: url('/20150810114905/assets/images/program-area-banner_1903x1000.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: inset 0 -70px 0 0 hsla(0, 0%, 96%, 0.85);
}

.camp-title.no-image {
	height: auto;
	padding-top: 1rem;
	padding-bottom: 0.85rem;
}

.camp-container {
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 1270px;
	padding: 0rem 2rem 6rem;
}

.photo-collage-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-width: 100%;
	padding-bottom: 6rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.photo-collage-3 > * {
	width: 100%;
	height: 450px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.photo-collage-3 :nth-child(1) {
	margin-right: 0.4rem;
}

.photo-collage-3 :nth-child(2) {
	margin-right: 0.2rem;
	margin-left: 0.2rem;
}

.photo-collage-3 :nth-child(3) {
	margin-left: 0.4rem;
}

.photo-collage-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1270px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-bottom: 6rem;
	padding-left: 2rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.photo-collage-2 > * {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 450px;
	width: 100%;
}

.photo-collage-2 :nth-child(1) {
	margin-right: 0.3rem;
}

.photo-collage-2 :nth-child(2) {
	margin-left: 0.3rem;
}

.photo-collage-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 6rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.photo-collage-1 :nth-child(1) {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 750px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(32, 189, 190, 0.35)), to(rgba(0, 107, 107, 0.65))), url('/20150810114905/assets/images/traditional-camp_1100x733.png');
	background-image: linear-gradient(180deg, rgba(32, 189, 190, 0.35), rgba(0, 107, 107, 0.65)), url('/20150810114905/assets/images/traditional-camp_1100x733.png');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

@media (max-width: 991px) {
	.container.anchor-link-div {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.anchor-link {
		font-size: 0.8rem;
		letter-spacing: 0.07rem;
		padding-left: 0.25rem;
		padding-right: 1.25rem;
	}

	.camp-container {
		padding-bottom: 4rem;
		padding-left: 0;
		padding-right: 0;
	}

	.photo-collage-3 {
		padding-bottom: 4rem;
	}

	.photo-collage-2 {
		padding-bottom: 4rem;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}

	.photo-collage-1 {
		padding-bottom: 4rem;
		padding-top: 0.6rem;
	}

	.photo-collage-1 :nth-child(1) {
		height: 500px;
	}
}

@media (max-width: 767px) {
	.anchor-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.anchor-link {
		margin-bottom: 0.25rem;
		margin-left: 0.3rem;
		margin-right: 0.3rem;
	}

	.camp-container {
		padding-bottom: 3rem;
	}

	.photo-collage-3 {
		-ms-flex-direction: column;
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
		-webkit-flex-direction: column;
		flex-direction: column;
		padding-bottom: 3rem;
	}

	.photo-collage-3 > * {
		height: 325px;
	}

	.photo-collage-3 :nth-child(1) {
		margin-right: 0rem;
		margin-bottom: 0.4rem;
	}

	.photo-collage-3 :nth-child(2) {
		margin: 0.2rem 0rem;
	}

	.photo-collage-3 :nth-child(3) {
		margin-left: 0rem;
		margin-top: 0.4rem;
	}

	.photo-collage-2 {
		padding-bottom: 3rem;
	}

	.photo-collage-1 {
		padding-bottom: 3rem;
	}

	.photo-collage-1 :nth-child(1) {
		height: 400px;
	}
}

@media (max-width: 479px) {
	.container.anchor-link-div {
		padding-bottom: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.anchor-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.anchor-link {
		font-size: 0.85rem;
		letter-spacing: 0.1rem;
		margin-bottom: 0.15rem;
	}

	.camp-title {
		margin-bottom: 1rem;
	}

	.camp-container {
		padding-bottom: 1.5rem;
	}

	.photo-collage-3 {
		-ms-flex-direction: column;
		-ms-flex-wrap: nowrap;
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
		-webkit-flex-direction: column;
		-webkit-flex-wrap: nowrap;
		flex-direction: column;
		flex-wrap: nowrap;
		min-height: 600px;
		padding-bottom: 1rem;
	}

	.photo-collage-3 > * {
		height: 200px;
	}

	.photo-collage-3 :nth-child(1) {
		margin-bottom: 0.4rem;
		margin-right: 0rem;
	}

	.photo-collage-3 :nth-child(2) {
		margin: 0.2rem 0rem;
	}

	.photo-collage-3 :nth-child(2) {
		margin-left: 0rem;
		margin-top: 0.4rem;
	}

	.photo-collage-2 {
		-ms-flex-direction: column;
		-ms-flex-wrap: nowrap;
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
		-webkit-flex-direction: column;
		-webkit-flex-wrap: nowrap;
		flex-direction: column;
		flex-wrap: nowrap;
		min-height: 400px;
		padding-bottom: 1rem;
	}

	.photo-collage-2 > * {
		height: 200px;
	}

	.photo-collage-2 :nth-child(1) {
		margin-bottom: 0.3rem;
		margin-right: 0rem;
	}

	.photo-collage-2 :nth-child(2) {
		margin-top: 0.3rem;
		margin-left: 0rem;
	}

	.photo-collage-1 {
		-ms-flex-direction: column;
		-ms-flex-wrap: nowrap;
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
		-webkit-flex-direction: column;
		-webkit-flex-wrap: nowrap;
		flex-direction: column;
		flex-wrap: nowrap;
		min-height: 200px;
		padding-bottom: 1rem;
		padding-top: 0rem;
	}

	.photo-collage-1 :nth-child(2) {
		height: 200px;
	}
}
