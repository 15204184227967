body {
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	font-size: 1rem;
	line-height: 1.5rem;
	background-color: #fff;
}

h1 {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	color: #006b6b;
	font-size: 2.375rem;
	line-height: 2.975rem;
	font-weight: 700;
}

h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #0077c8;
	font-size: 2rem;
	line-height: 2.6rem;
	font-weight: 700;
}

h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #0077c8;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 700;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #0077c8;
	font-size: 1.25rem;
	line-height: 1.85rem;
	font-weight: 700;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #0077c8;
	font-size: 1.09rem;
	line-height: 1.6rem;
	font-weight: 700;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #0077c8;
	font-size: 0.94rem;
	line-height: 1.6rem;
	font-weight: 700;
	text-transform: uppercase;
}

p {
	margin-bottom: 0.75rem;
	font-size: 1rem;
	line-height: 1.6rem;
}

a {
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: underline;
	background-color: #fff;
	color: #0077c8;
}

ul {
	margin-top: 0px;
	margin-bottom: 0.7rem;
	padding-left: 20px;
}

ol {
	margin-top: 0px;
	margin-bottom: 0.7rem;
	padding-left: 20px;
}

li {
	margin-bottom: 0.25rem;
}

blockquote {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding: 1.25rem;
	border-top: 5px solid #20bdbe;
	border-bottom: 5px solid #20bdbe;
	border-left: 1px none #000;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.ui-element-heading {
	margin-top: 2rem;
	margin-bottom: 1.5rem;
}

.field-label {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 600;
}

.text-link {
	padding-bottom: 3px;
	border-bottom: 1px dashed #a0a0a0;
	box-shadow: none;
	-webkit-transition-duration: 350ms;
	transition-duration: 350ms;
	color: #0077c8;
	text-decoration: none;
}

.text-link:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -1px 0 0 transparent;
	color: #0060af;
}

.right-col-item {
	margin-bottom: 2rem;
}

.intro-paragraph {
	display: block;
	margin-bottom: 1.25rem;
	font-size: 1.3rem;
	line-height: 2rem;
	font-weight: 400;
}

.right-col-divider {
	width: 50px;
	height: 4px;
	margin-top: 0.5rem;
	background-color: #01a490;
}

.styles-logo-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	margin-top: 22px;
	margin-bottom: 22px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.screen-message {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

.screen-message.success {
	border-left: 6px solid #1ba53c;
	background-color: rgba(27, 165, 60, 0.2);
}

.screen-message.error {
	border-left: 6px solid #e70417;
	background-color: rgba(253, 54, 54, 0.15);
}

.button {
	display: inline-block;
	min-width: 110px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 0.6rem 1rem;
	border: 2px solid #0077c8;
	border-radius: 8px;
	background-color: #0077c8;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.03rem;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	border-width: 2px;
	border-color: #0077c8;
	background-color: #fff;
	box-shadow: none;
	color: #0077c8;
}

.button.secondary {
	border-color: #636466;
	background-color: #636466;
}

.button.secondary:hover {
	background-color: #fff;
	color: #636466;
}

.button.tertiary {
	min-width: 70px;
	padding: 0.4rem 0.5rem;
	border: 2px solid #231f20;
	background-color: #231f20;
	font-size: 0.85rem;
	line-height: 1rem;
}

.button.tertiary:hover {
	border: 2px solid #231f20;
	background-color: #fff;
	color: #231f20;
}

.header-link {
	display: inline;
	border-bottom: 1px dashed #a0a0a0;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #0077c8;
	text-decoration: none;
}

.header-link:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -1px 0 0 transparent;
	color: #0060af;
}

.button-styles {
	margin-bottom: 32px;
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.fake_wysiwyg-image {
	width: 100%;
}

.main-content {
	width: 100%;
}

.main-content.no-sidebars {
	display: block;
	max-width: 1024px;
	margin-right: auto;
	margin-left: auto;
}

.main-content.program-area-no-sidebars {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.right-side-col {
	width: 22%;
	max-width: 250px;
	min-width: 168px;
	margin-left: 3vw;
}

.right-side-col h2 {
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-side-col h3 {
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-side-col p {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col ol {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col h4 {
	margin-top: 20px;
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-side-col ul {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.flexy-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-section {
	position: fixed;
	z-index: 10000;
	width: 100%;
	background-color: #fff;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.container {
	max-width: 1400px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
}

.container.cta-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100px;
	max-width: none;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-top: 5px none #fff;
	border-bottom: 5px none #fff;
}

.container.footer-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding: 2rem 8vw;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-top: 6px solid #0060af;
	background-image: none;
}

.container.inside-page-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.container.breadcrumb-container {
	padding: 9px 0.75rem 8px 0.75rem;
}

.container.anchor-link-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.75rem;
}

.container-4 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container-4.signoff-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	padding-top: 0.5rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	text-align: center;
}

.home-section {
	position: relative;
}

.dropdown-list {
	position: absolute;
	display: none;
	overflow: visible;
	height: 0px;
	max-width: none;
	min-width: 160px;
	padding: 0rem;
	background-color: #fff;
	box-shadow: 0 -1px 5px 0 rgba(0, 0, 0, 0.25);
	opacity: 0;
	text-align: left;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.nav-menu {
	display: block;
	width: 100%;
	float: none;
	color: #fff;
}

.main-nav-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	text-align: right;
}

.utilities-link {
	display: inline-block;
	margin-right: 0.25rem;
	margin-left: 0.25rem;
	padding: 0.3rem 0.75rem;
	border-radius: 8px;
	color: #0077c8;
	font-size: 0.78rem;
	line-height: 1.1rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
	float: left;
	background-color: transparent;
}

.utilities-link:hover {
	background-color: #0077c8;
	color: #fff;
}

.utilities-link.w--current {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#0077c8), to(#0077c8));
	background-image: linear-gradient(180deg, #0077c8, #0077c8);
	color: #fff;
}

.utilities-link.last-link {
	margin-right: 0rem;
}

.dropdown-link {
	display: block;
	width: 100%;
	margin-bottom: 2px;
	padding: 0.5rem 0.75rem;
	border-radius: 8px;
	color: #231f20;
	font-size: 0.88rem;
	line-height: 1.1rem;
	font-weight: 400;
	text-decoration: none;
}

.dropdown-link:hover {
	border-radius: 10px;
	background-color: #0077c8;
	color: #fff;
}

.dropdown-link.w--current {
	background-color: #0077c8;
	color: #fff;
	cursor: pointer;
}

.utilities {
	position: relative;
	margin-top: 0.75rem;
	margin-bottom: 7px;
	padding-right: 1.25rem;
	-webkit-transition: margin 300ms ease;
	transition: margin 300ms ease;
	text-align: right;
}

.mobile-menu-arrow {
	display: none;
}

.main-nav-link {
	position: relative;
	display: block;
	margin-left: 0px;
	padding: 0.6rem 0.75rem;
	border-radius: 8px;
	background-color: transparent;
	color: #0077c8;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.main-nav-link:hover {
	background-color: #0077c8;
	color: #fff;
}

.main-nav-link.w--current {
	border-bottom-color: #01a1dd;
	background-color: #0077c8;
	color: #fff;
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.header-right-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.navbar {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 136px;
	max-width: 1600px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 20px;
	padding-left: 30px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: transparent;
}

.header-left-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 1.25rem;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.areas-of-focus-wrap {
	height: 49px;
}

.dd-toggle {
	padding: 0px;
}

.main-nav-dd-widget {
	margin-right: 0rem;
	margin-bottom: 7px;
	margin-left: 2vw;
}

.nav-drop-list {
	overflow: hidden;
}

.nav-drop-list.w--open {
	top: 100%;
	min-width: 150px;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
	color: #000;
}

.slider-arrow-2 {
	width: 80px;
	height: 80px;
	margin-top: auto;
	margin-bottom: 0px;
	opacity: 0.6;
	-webkit-transition: opacity 200ms ease;
	transition: opacity 200ms ease;
	font-size: 2rem;
}

.slider-arrow-2:hover {
	opacity: 1;
}

.slider-title {
	display: inline-block;
	min-width: 200px;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	padding: 8px 1.5rem 8px 3rem;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: rgba(0, 107, 107, 0.8);
	color: #fff;
	font-size: 3.75rem;
	line-height: 4.5rem;
	font-weight: 700;
	text-transform: uppercase;
}

.slider-text {
	display: inline-block;
	padding: 6px 1rem 6px 3rem;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: rgba(0, 107, 107, 0.85);
	color: #fff;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 400;
}

.slider-indicators {
	height: 2rem;
	margin-bottom: 1.5rem;
	opacity: 1;
	font-size: 0.75rem;
}

.cta-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 1px solid #0060af;
	background-color: #0077c8;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.cta-link:hover {
	background-color: hsla(0, 0%, 100%, 0.66);
	color: #0060af;
}

.cb-hover-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	border-radius: 7px 7px 8px 8px;
	background-color: #006b6b;
	opacity: 0;
	-webkit-transition: opacity 250ms ease;
	transition: opacity 250ms ease;
	text-align: center;
}

.cb-hover-overlay:hover {
	opacity: 0.25;
}

.cb-title-underline {
	width: 100%;
	height: 5px;
	background-color: #f42535;
	opacity: 0;
}

.row-2-cb {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 33.33%;
	height: 350px;
	margin-right: 1vw;
	margin-left: 1vw;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	border-radius: 10px 10px 8px 8px;
	background-color: #0089d0;
	background-image: url('/20150810114905/assets/images/example-bg.png');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
	text-decoration: none;
}

.row-2-cb:hover {
	box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);
	-webkit-transform: translate(0px, -12px);
	-ms-transform: translate(0px, -12px);
	transform: translate(0px, -12px);
}

.row-2-cb.cb-image-3 {
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.row-2-cb.program-area-cb-image-1 {
	overflow: hidden;
	margin-right: 1rem;
	margin-left: 0vw;
	background-image: url('/20150810114905/assets/images/gettyimages-930030650-170667a.jpg');
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.row-2-cb.program-area-cb-image-1:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
}

.row-2-cb.program-area-cb-image-2 {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-image: url('/20150810114905/assets/images/gettyimages-936316066-170667a.jpg');
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.row-2-cb.program-area-cb-image-2:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
}

.row-2-cb.program-area-cb-image-3 {
	margin-right: 0vw;
	margin-left: 1rem;
	background-image: url('/20150810114905/assets/images/gettyimages-177506061-170667a.jpg');
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.row-2-cb.program-area-cb-image-3:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
}

.row-2-cb.program-area-cb-image-4 {
	overflow: hidden;
	margin-right: 1rem;
	margin-left: 0vw;
	background-image: url('/20150810114905/assets/images/gettyimages-851934612-170667a.jpg');
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.row-2-cb.program-area-cb-image-4:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
}

.row-2-cb.program-area-cb-image-5 {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	background-image: url('/20150810114905/assets/images/gettyimages-123905412-170667a.jpg');
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.row-2-cb.program-area-cb-image-5:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
}

.row-2-cb.program-area-cb-image-6 {
	margin-right: 0vw;
	margin-left: 1rem;
	background-image: url('/20150810114905/assets/images/gettyimages-687686154-170667a.jpg');
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.row-2-cb.program-area-cb-image-6:hover {
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
}

.cb-title-wrap {
	position: relative;
	width: 110%;
	padding: 1rem;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	background-color: #006b6b;
	color: #fff;
	text-align: center;
}

.row-2-cb-header {
	position: relative;
	margin-top: 0px;
	margin-bottom: 0px;
	color: #fff;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	text-shadow: none;
}

.slider-button {
	display: inline-block;
	min-width: 110px;
	margin: 1rem 1.5rem 0.5rem 3rem;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	background-color: #fcaf17;
	color: #231f20;
	font-size: 0.85rem;
	line-height: 1.1rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0rem;
	text-decoration: none;
	text-transform: uppercase;
}

.slider-button:hover {
	border-color: #000;
	background-color: #fff;
	box-shadow: none;
	color: #000;
}

.dd-wrap {
	margin-top: 0.5rem;
	padding: 0.25rem 0.5rem;
}

.fb-embed-wrapper {
	overflow: hidden;
	margin-left: 5vw;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.fb-embed {
	width: 400px;
}

.container-row-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 5vw 1vw 6vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: transparent;
}

.video-wrapper {
	display: block;
	overflow: hidden;
	width: 55vw;
	height: auto;
	min-height: 27vw;
	margin-right: 0px;
	border: 1px solid #fff;
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
	padding: 20px;
}

.translate-wrapper {
	display: inline-block;
	border-radius: 6px;
	-webkit-transition-property: none;
	transition-property: none;
	color: #0077c8;
	font-size: 0.78rem;
	line-height: 1.1rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
}

.footer-heading {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	color: #0077c8;
	font-size: 1.15rem;
	line-height: 1.7rem;
	text-transform: uppercase;
}

.footer-nav-list {
	display: block;
	margin-top: 0.25rem;
	font-size: 0.92rem;
	text-decoration: none;
}

.footer-nav-list:hover {
	color: #b3d236;
}

.social-media-link-block {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 36px;
	height: 36px;
	margin-right: 0.4rem;
	margin-left: 0.4rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-radius: 18px;
	background-color: #0060af;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-align: center;
}

.social-media-link-block:hover {
	border-radius: 4px;
	background-color: #0077c8;
}

.social-media-link-block.first-social-link {
	margin-left: 0rem;
}

.social-media-link-block.first-social-link:hover {
	opacity: 1;
}

.footer-nav-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-top: 0rem;
	padding-bottom: 0rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.footer-col-wrap {
	width: 50%;
	padding-right: 1rem;
}

.footer-col-wrap.last-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.footer-nav-item {
	margin-top: 0rem;
	margin-bottom: 0.25rem;
}

.footer-paragraph {
	margin-bottom: 0px;
	color: #231f20;
	font-size: 0.92rem;
	line-height: 1.5rem;
}

.footer-paragraph.phone-paragraph {
	margin-top: 10px;
	padding-left: 1.5rem;
	background-image: url('/20150810114905/assets/images/fontawesome-phone-black-231f20.svg');
	background-position: 0px 50%;
	background-size: 18px 18px;
	background-repeat: no-repeat;
	color: #231f20;
}

.footer-paragraph.directions-paragraph {
	margin-top: 10px;
	padding-left: 1.7rem;
	background-image: url('/20150810114905/assets/images/fontawesome-map-pin-black_231f20.svg');
	background-position: -3px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	color: #231f20;
}

.social-media-wrapper {
	position: relative;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 2.5rem;
	margin-top: 0rem;
	margin-bottom: 2rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.accred-link {
	margin-top: 2rem;
}

.phone-link {
	color: #231f20;
	text-decoration: none;
}

.footer-nav-list-link {
	display: block;
	margin-top: 0.25rem;
	padding-left: 0.75rem;
	background-image: url('/20150810114905/assets/images/chevron-right_231f20.svg');
	background-position: 0px 49%;
	background-size: 7px 12px;
	background-repeat: no-repeat;
	color: #231f20;
	text-decoration: none;
}

.footer-nav-list-link:hover {
	background-image: url('/20150810114905/assets/images/chevron-right_0077C8.svg');
	background-size: 7px 12px;
	-webkit-transform: translate(5px, 0px);
	-ms-transform: translate(5px, 0px);
	transform: translate(5px, 0px);
	color: #0077c8;
}

.footer-nav-list-link.w--current {
	padding-left: 0.75rem;
	background-image: url('/20150810114905/assets/images/chevron-right_0077C8.svg');
	background-size: 7px 12px;
	color: #0077c8;
	font-weight: 700;
}

.footer-nav-list-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.ycamp-logo-wrap {
	width: 125px;
	margin-bottom: 1rem;
}

.directions-link {
	border-bottom: 1px dashed #a0a0a0;
	color: #231f20;
	text-decoration: none;
}

.directions-link:hover {
	border-bottom-color: transparent;
	color: #0077c8;
}

.translate-embed {
	text-align: left;
}

.ycamp-header-title {
	color: #006b6b;
	font-size: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;
}

.ycamp-title-wrap {
	margin-bottom: 1vw;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.camp-name-wrap {
	margin-top: 7px;
}

.fontawesome-right {
	margin-left: 0.5rem;
	font-family: 'Fa solid 900', sans-serif;
}

.footer-grip-link {
	width: 130px;
	height: 28px;
	min-width: 0px;
	margin-top: 1rem;
	padding-left: 32px;
	background-image: url('/20150810114905/assets/images/globalreach_knockout_fullwhite-optimized.svg');
	background-position: 50% 50%;
	background-size: 130px 28px;
	background-repeat: no-repeat;
	text-decoration: none;
}

.grip-logo-text-box {
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #fff;
	font-size: 0.5rem;
	line-height: 8px;
	text-decoration: none;
	text-transform: uppercase;
}

.signoff-left {
	width: 70%;
	margin-top: 1rem;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.2rem;
	text-align: left;
}

.signoff-links-wrap {
	margin-bottom: 1.5rem;
	font-size: 0.8rem;
}

.copyright-paragraph {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 0.25rem;
	margin-bottom: 0.25rem;
	float: left;
	color: #ddd;
	font-size: 0.8rem;
	line-height: 1.2rem;
}

.signoff-link {
	margin-right: 0.75rem;
	padding-bottom: 2px;
	border-bottom: 1px dashed #bbb;
	color: #ddd;
	text-decoration: none;
	background-color: transparent;
}

.signoff-link:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -1px 0 0 transparent;
	color: #fff;
	text-decoration: none;
}

.signoff-section {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 8vw;
	padding-left: 8vw;
	border-top: 1px solid hsla(0, 0%, 100%, 0.2);
	background-color: #0060af;
}

._2x-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
}

.rotator {
	height: 75vh;
	min-height: 446px;
	background-color: #e2e2e2;
}

.slideshow-image-wrap {
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.slideshow-image-wrap.slide-1 {
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.slideshow-image-wrap.slide-2 {
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.slideshow-image-wrap.slide-3 {
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.rotator-section {
	position: relative;
	z-index: 1;
	padding-top: 136px;
}

.slider-slide {
	background-color: #fff;
	font-family: inherit;
}

.slide-caption-wrapper {
	position: relative;
	left: 0px;
	right: 0px;
	bottom: 45%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	max-width: 90%;
	min-width: 350px;
	margin-right: auto;
	margin-left: 0px;
	padding-top: 1.5rem;
	padding-right: 2rem;
	padding-bottom: 1rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.horizontal-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid rgba(99, 100, 102, 0.5);
}

.container-row-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 6.5rem 8vw;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.21)), to(rgba(0, 0, 0, 0.21))), url('/20150810114905/assets/images/shley-tree-1b_282919.png');
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.21), rgba(0, 0, 0, 0.21)), url('/20150810114905/assets/images/shley-tree-1b_282919.png');
	background-position: 0px 0px, 0px 0px;
	background-size: auto, auto;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.3);
}

.home-section-outer-wrap {
	background-image: url('/20150810114905/assets/images/GettyImages-921341760_tiny.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.container-row-3-top {
	height: 4px;
	padding-right: 8vw;
	padding-left: 8vw;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-image: url('/20150810114905/assets/images/shley-tree-1_282919_rough-top_slice.png');
	background-position: 0px 0px;
	background-repeat: repeat-x;
}

.styles-container {
	padding-bottom: 3rem;
}

.inside-page-banner {
	width: 100%;
	height: 300px;
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.inside-page-wrapper {
	padding-top: 136px;
}

.inside-content-section {
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.content-outer-wrap {
	display: block;
	margin-right: 2vw;
	margin-left: 2vw;
	padding-top: 0rem;
	padding-bottom: 3rem;
}

.content-outer-wrap.program-page {
	width: 100%;
	margin-right: 0vw;
	margin-left: 0vw;
}

.left-nav-wrapper {
	width: 28%;
	height: 100%;
	max-width: 250px;
	min-width: 200px;
	margin-right: 3vw;
}

.left-nav-link-wrap {
	border-bottom: 1px dashed rgba(0, 119, 200, 0.5);
}

.left-nav-link-wrap.gc-left-link-wrap {
	border-bottom-color: rgba(99, 100, 102, 0.2);
}

.left-nav-list-item.grandchild-li {
	margin-left: 1rem;
}

.left-nav-list-item.greatgrandchild-li {
	margin-left: 0.5rem;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-bottom: 0.5rem;
	background-color: transparent;
	font-size: 1rem;
	line-height: 1.375rem;
	font-weight: 400;
	text-decoration: none;
}

.left-nav-section-title-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.5rem 0.5rem 0.5rem 0.75rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #fff;
	text-decoration: none;
}

.left-nav-list-link {
	display: block;
	padding: 0.5rem 1.75rem 0.5rem 0.75rem;
	background-image: url('/20150810114905/assets/images/chevron-right_0077C8.svg');
	background-position: -10px 12px;
	background-size: 7px 12px;
	background-repeat: no-repeat;
	color: #363636;
	font-size: 0.9rem;
	text-decoration: none;
}

.left-nav-list-link:hover {
	background-position: 0px 12px;
	-webkit-transform: translate(8px, 0px);
	-ms-transform: translate(8px, 0px);
	transform: translate(8px, 0px);
	color: #0077c8;
}

.left-nav-list-link.w--current {
	padding-right: 0.25rem;
	padding-left: 1.25rem;
	background-color: #e5f1f9;
	background-position: 8px 50%;
	background-size: 7px 14px;
	color: #006eb8;
}

.left-nav-list-link.w--current:hover {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-list-link.greatgrandchild-link {
	background-size: 5px 10px;
	font-size: 0.78rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.left-nav-list-link.greatgrandchild-link:hover {
	background-size: 6px 12px;
}

.left-nav-list-link.greatgrandchild-link.w--current {
	background-image: url('/20150810114905/assets/images/chevron-right_0077C8.svg');
	background-size: 5px 10px;
	background-repeat: no-repeat;
	color: #0077c8;
}

.left-nav-list-link.greatgrandchild-link.w--current:hover {
	background-size: 5px 10px;
}

.left-nav-list-link.grandchild-link {
	background-size: 6px 12px;
	font-size: 0.82rem;
	line-height: 1.3rem;
	font-weight: 400;
}

.left-nav-list-link.grandchild-link:hover {
	background-size: 6px 12px;
}

.left-nav-list-link.grandchild-link.w--current {
	background-size: 6px 12px;
	color: #0077c8;
}

.left-nav-section-title {
	margin-top: 0rem;
	margin-bottom: 0rem;
	font-size: 1.1rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-transform: uppercase;
}

.main-content-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 1400px;
	padding-top: 2.75rem;
	width: 100%;
	min-height: 800px;
}

.main-content-wrap.program-page {
	position: relative;
	width: 100%;
	max-width: 100%;
	padding-top: 5rem;
}

.main-content-wrap.our-programs {
	width: 100%;
}

.breadcrumbs-list {
	margin-bottom: 0rem;
	padding-bottom: 2px;
	padding-left: 0px;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
	color: #fff;
	font-size: 0.8rem;
}

.breadcrumb-link {
	padding-bottom: 2px;
	border-bottom: 1px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	background-color: transparent;
}

.breadcrumb-link:hover {
	border-bottom-style: dashed;
	border-bottom-color: #cfcfcf;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
	color: #fff;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.7rem;
	color: #fff;
	font-size: 1rem;
}

.bcrumbs-anchor-section {
	background-color: #0077c8;
	font-weight: normal;
}

.highlight {
	background-color: #ffeb00;
	font-family: inherit;
}

.link-styles {
	margin-bottom: 32px;
}

.program-area-inside-page-banner {
	width: 100%;
	height: 500px;
	background-image: url('/20150810114905/assets/images/y-cutout-3_80-25.png'), url('/20150810114905/assets/images/program-area-banner_1903x1000.png');
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.text-block {
	margin-bottom: 1rem;
	color: #006b6b;
	font-size: 2.375rem;
	line-height: 2.975rem;
	font-weight: 700;
	text-transform: uppercase;
}

.program-area-container-1 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: center;
	-ms-flex-pack: justify;
	justify-content: center;
	background-color: transparent;
	background-image: none;
}

.program-area-intro-paragraph {
	display: block;
	margin-bottom: 1.25rem;
	font-size: 1.3rem;
	line-height: 2rem;
	font-weight: 400;
}

.card-title {
	position: relative;
	margin-top: 0px;
	margin-bottom: 0px;
	color: #fff;
	font-size: 1.15rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;
	text-shadow: none;
}

.program-page-inside-page-banner {
	width: 100%;
	height: 500px;
	background-image: url('/20150810114905/assets/images/y-cutout-3_80-25.png'), url('/20150810114905/assets/images/program-page-banner_1903x1000.png');
	background-position: 50% 50%, 50% 50%;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.anchor-link {
	padding: 0.25rem 1.5rem 0.25rem 0.5rem;
	border-bottom: 2px solid #0077c8;
	background-image: url('/20150810114905/assets/images/chevron-right-LTteal-01.svg');
	background-position: 96% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.85rem;
	letter-spacing: 0.1rem;
	text-decoration: none;
	text-transform: uppercase;
	background-color: transparent;
}

.anchor-link:hover {
	border-bottom-color: hsla(0, 0%, 100%, 0.3);
	background-image: url('/20150810114905/assets/images/chevron-right-white-01.svg');
	background-position: 100% 50%;
	background-size: 12px 12px;
}

.anchor-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.right-side-col-program-page {
	width: 22%;
	max-width: 250px;
	min-width: 168px;
	margin-left: 3vw;
}

.right-side-col-program-page h2 {
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-side-col-program-page h3 {
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-side-col-program-page p {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col-program-page ol {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col-program-page h4 {
	margin-top: 20px;
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-side-col-program-page ul {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.camp-overview-div {
	display: block;
	max-width: 1270px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-bottom: 4rem;
	padding-left: 2rem;
}

.age-group-text {
	color: #636466;
	font-size: 0.85rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.camp-date {
	padding-bottom: 0.5rem;
	color: #636466;
	font-size: 0.85rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.specialty-camp-1 {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.camp-price {
	padding-bottom: 0.5rem;
	color: #636466;
	font-size: 0.85rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
}

.specialty-camp-2 {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.specialty-camp-3 {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.specialty-camp-4 {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.specialty-camp-5 {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.specialty-camp-6 {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.specialty-camp-6.last {
	padding-bottom: 0rem;
	border-bottom-style: none;
}

.ranch-camp-1 {
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.ranch-camp-2 {
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.ranch-camp-3 {
	padding-top: 1rem;
	padding-bottom: 2rem;
	border-bottom: 2px solid hsla(0, 1%, 47%, 0.35);
}

.ranch-camp-3.last {
	width: 100%;
	padding-bottom: 0rem;
	border-bottom-style: none;
}

.back-to-top {
	position: fixed;
	right: 10px;
	bottom: 10px;
	z-index: 10000;
	display: inline;
	width: 60px;
	height: 60px;
	margin-right: auto;
	margin-left: auto;
	padding: 0.5rem 1rem;
	border-radius: 50%;
	background-color: #20bdbe;
	background-image: url('/20150810114905/assets/images/arrow-white-up.svg');
	background-position: 50% 50%;
	background-size: 24px 24px;
	background-repeat: no-repeat;
	box-shadow: -1px 4px 8px 0 rgba(0, 0, 0, 0.35);
	opacity: 1;
	-webkit-transition-property: all;
	transition-property: all;
	color: #000;
	font-size: 0.8rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03rem;
	text-decoration: none;
	text-transform: uppercase;
}

.back-to-top:hover {
	background-color: #231f20;
	box-shadow: -2px 9px 15px 2px rgba(0, 0, 0, 0.2);
}

.back-to-top:active {
	box-shadow: -1px 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.top {
	width: 100%;
	height: 0px;
}

.brandlink {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 1.25rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	background-color: transparent;
}

.logo-wrap {
	position: relative;
	display: block;
	width: 125px;
	margin-right: 1.25vw;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
}

.styles-tagline-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 200px;
	margin-top: 22px;
	margin-bottom: 22px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.tagline {
	height: 100%;
}

html.w-mod-js *[data-ix="slide-learn-more"] {
	opacity: 0;
}

html.w-mod-js *[data-ix="slidecaptionheader"] {
	opacity: 0;
	-webkit-transform: translate(-300px, 0px);
	-ms-transform: translate(-300px, 0px);
	transform: translate(-300px, 0px);
}

html.w-mod-js *[data-ix="slidecaptionparagraph"] {
	opacity: 0;
	-webkit-transform: translate(-300px, 0px);
	-ms-transform: translate(-300px, 0px);
	transform: translate(-300px, 0px);
}

html.w-mod-js *[data-ix="slidecaptionbutton"] {
	opacity: 0;
	-webkit-transform: translate(-300px, 0px);
	-ms-transform: translate(-300px, 0px);
	transform: translate(-300px, 0px);
}

html.w-mod-js *[data-ix="cb-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 150px);
	-ms-transform: translate(0px, 150px);
	transform: translate(0px, 150px);
}

html.w-mod-js *[data-ix="cb-load-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, 150px);
	-ms-transform: translate(0px, 150px);
	transform: translate(0px, 150px);
}

html.w-mod-js *[data-ix="cb-load-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, 150px);
	-ms-transform: translate(0px, 150px);
	transform: translate(0px, 150px);
}

html.w-mod-js *[data-ix="cb-row-scroll-load"] {
	opacity: 0;
	-webkit-transform: translate(0px, 200px);
	-ms-transform: translate(0px, 200px);
	transform: translate(0px, 200px);
}

html.w-mod-js *[data-ix="hidden-on-load"] {
	display: block;
	opacity: 0;
}

@media (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.85rem;
	}

	h2 {
		font-size: 1.875rem;
		line-height: 2.475rem;
	}

	h3 {
		font-size: 1.375rem;
		line-height: 1.975rem;
	}

	h4 {
		font-size: 1.175rem;
		line-height: 1.775rem;
	}

	.main-content {
		width: 100%;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 1rem;
		margin-left: 0rem;
		padding: 1rem 0rem;
	}

	.container.footer-container {
		padding-right: 3.75rem;
		padding-left: 3.75rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.container.breadcrumb-container {
		max-width: 100%;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container.anchor-link-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container-4 {
		max-width: 991px;
	}

	.container-4.signoff-container {
		padding-top: 0.5rem;
		padding-bottom: 3rem;
		text-align: left;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.two-part-mobile-nav-wrapper {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
	}

	.nav-menu {
		display: block;
		width: 75%;
		margin-top: 120px;
		padding-top: 4px;
		padding-right: 2px;
		padding-left: 2px;
		background-color: #0060af;
		text-align: left;
	}

	.main-nav-container {
		display: block;
		max-width: 940px;
	}

	.mobile-menu-icon {
		font-size: 3rem;
		line-height: 3rem;
		text-align: center;
	}

	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding: 5px;
		float: right;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-transition: background-color 300ms ease;
		transition: background-color 300ms ease;
		color: #0077c8;
	}

	.menu-button.w--open {
		background-color: transparent;
		color: #0077c8;
		cursor: pointer;
	}

	.utilities {
		top: 0px;
		display: none;
	}

	.mobile-link-label {
		display: inline-block;
		margin-top: 0rem;
		margin-bottom: 0rem;
		line-height: 1.2rem;
	}

	.mobile-menu-arrow {
		display: block;
		width: 15%;
		border-left: 1px solid hsla(0, 0%, 100%, 0.25);
		background-image: url('/20150810114905/assets/images/chevron-right_white.svg');
		background-position: 50% 50%;
		background-size: 12px 24px;
		background-repeat: no-repeat;
		color: #000;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(35, 31, 32, 0.2);
		opacity: 1;
	}

	.main-nav-link {
		margin-top: 0rem;
		padding: 1rem 1rem 1rem 1.25rem;
		border-bottom: 1px solid hsla(0, 0%, 100%, 0.25);
		border-radius: 0px;
		-webkit-transition: none 0ms ease;
		transition: none 0ms ease;
		color: #fff;
		text-align: left;
	}

	.main-nav-link:hover {
		border-bottom-width: 1px;
		border-bottom-color: #979797;
		background-color: rgba(35, 31, 32, 0.2);
	}

	.main-nav-link.w--current {
		border-bottom-color: hsla(0, 0%, 100%, 0.25);
		background-color: rgba(35, 31, 32, 0.2);
	}

	.main-nav-link.mobile-duplicate {
		display: block;
		width: 88%;
		margin-right: 0px;
		margin-left: 0px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		border-bottom-style: none;
		-webkit-transition-property: all;
		transition-property: all;
	}

	.header-right-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.menu-button-text {
		display: none;
		margin-top: -8px;
		font-size: 0.7rem;
		line-height: 1rem;
		text-align: center;
	}

	.navbar {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.header-left-wrapper {
		width: 75%;
	}

	.areas-of-focus-wrap {
		width: 170px;
		height: auto;
	}

	.slider-arrow-2 {
		width: 60px;
		height: 50px;
	}

	.slider-title {
		background-color: #006b6b;
		font-size: 2.5rem;
		line-height: 3rem;
	}

	.slider-text {
		padding-top: 8px;
		padding-bottom: 8px;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}

	.slider-indicators {
		margin-bottom: 0.65rem;
	}

	.cta-link {
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	.row-2-cb {
		width: 100%;
		height: 350px;
		margin-right: 0rem;
		margin-bottom: 2rem;
		margin-left: 0vw;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.row-2-cb.cb-image-1 {
		background-image: url('/20150810114905/assets/images/img_5993_rotated_1903w.jpg');
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.row-2-cb.program-area-cb-image-1 {
		margin-right: 0rem;
		background-image: url('/20150810114905/assets/images/img_5993_rotated_1903w.jpg');
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.row-2-cb.program-area-cb-image-2 {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.row-2-cb.program-area-cb-image-3 {
		margin-bottom: 0.5rem;
		margin-left: 0rem;
	}

	.row-2-cb.program-area-cb-image-4 {
		margin-right: 0rem;
		background-image: url('/20150810114905/assets/images/img_5993_rotated_1903w.jpg');
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.row-2-cb.program-area-cb-image-5 {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.row-2-cb.program-area-cb-image-6 {
		margin-left: 0rem;
	}

	.cb-title-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30%;
		height: 100%;
		padding-top: 2rem;
		padding-left: 1.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		border-top-right-radius: 0px;
		border-bottom-left-radius: 8px;
		background-color: rgba(0, 107, 107, 0.85);
	}

	.row-2-cb-header {
		text-align: left;
	}

	.fb-embed-wrapper {
		margin-left: 2rem;
	}

	.fb-embed {
		width: 240px;
	}

	.container-row-2 {
		padding: 3rem 2rem 1rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.video-wrapper {
		width: 57vw;
		min-height: 32vw;
	}

	.footer-heading {
		margin-top: 0rem;
	}

	.social-media-link-block {
		-webkit-transition-property: all;
		transition-property: all;
		-webkit-transition-duration: 300ms;
		transition-duration: 300ms;
	}

	.social-media-link-block:hover {
		border-radius: 0%;
	}

	.social-media-link-block.first-social-link {
		margin-left: 0rem;
	}

	.footer-nav-wrapper {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-col-wrap {
		width: 50%;
		min-width: 0px;
		margin-top: 0.5rem;
	}

	.footer-col-wrap.last-col {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 2rem;
		padding-top: 2rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		border-top: 1px solid #636466;
	}

	.footer-col-wrap.footer-col-wrap-3 {
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: 1px solid #636466;
	}

	.footer-nav-item {
		margin-bottom: 0.35rem;
	}

	.social-outer-wrap {
		width: 100%;
	}

	.ycamp-header-title {
		font-size: 1.3rem;
	}

	.ycamp-title-wrap {
		margin-bottom: 0.5rem;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.camp-name-wrap {
		margin-top: 4px;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.footer-grip-link {
		margin-top: 1rem;
	}

	.grip-logo-text-box {
		float: right;
	}

	.signoff-left {
		width: 75%;
	}

	.signoff-links-wrap {
		margin-left: 0rem;
	}

	.copyright-paragraph {
		margin-bottom: 0.5rem;
	}

	._2x-wrap {
		width: 100%;
	}

	.rotator {
		height: auto;
		background-color: #20bdbe;
	}

	.slideshow-image-wrap {
		height: 400px;
		background-position: 50% 50%, 50% 50%;
		background-size: cover, cover;
		background-repeat: no-repeat, no-repeat;
	}

	.rotator-section {
		padding-top: 120px;
	}

	.slide-arrow-icon {
		font-size: 1.5rem;
	}

	.slider-slide {
		font-family: inherit;
		background-color: transparent;
	}

	.slide-caption-wrapper {
		position: static;
		top: 0%;
		display: block;
		overflow: visible;
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 3rem 3rem 0rem;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.container-row-3 {
		padding: 3rem 1rem;
	}

	.inside-page-wrapper {
		padding-top: 120px;
	}

	.content-outer-wrap {
		margin-right: 0vw;
		margin-left: 0vw;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.left-nav-wrapper {
		display: none;
	}

	.main-content-wrap {
		padding-top: 2rem;
		padding-right: 0vw;
		padding-left: 0vw;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.main-content-wrap.program-page {
		padding-top: 4rem;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.program-area-container-1 {
		padding-top: 1.5rem;
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.card-title {
		text-align: left;
	}

	.anchor-link {
		padding-right: 1.25rem;
		padding-left: 0.25rem;
		font-size: 0.8rem;
		letter-spacing: 0.07rem;
	}

	.right-side-col-program-page {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 1rem;
		margin-left: 0rem;
		padding: 1rem 0rem;
	}

	.camp-overview-div {
		padding-right: 0rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
	}

	.back-to-top {
		right: 5px;
		bottom: 15px;
		display: inline-block;
		width: 50px;
		height: 50px;
	}

	.brandlink {
		margin-top: 1rem;
		margin-right: 0.85rem;
	}

	.logo-wrap {
		width: 110px;
		margin-right: 0.85rem;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 2rem;
		line-height: 2.6rem;
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2.35rem;
	}

	h3 {
		font-size: 1.25rem;
		line-height: 1.85rem;
	}

	h4 {
		font-size: 1.1rem;
		line-height: 1.6rem;
	}

	h5 {
		font-size: 0.97rem;
		line-height: 1.55rem;
	}

	h6 {
		font-size: 0.91rem;
		line-height: 1.55rem;
	}

	ul {
		padding-left: 20px;
	}

	ol {
		padding-left: 20px;
	}

	blockquote {
		font-size: 1.15rem;
		line-height: 1.65rem;
	}

	.intro-paragraph {
		font-size: 1.2rem;
		line-height: 1.9rem;
	}

	.right-side-col ul {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.right-side-col li {
		line-height: 1.45rem;
	}

	.header-section {
		background-color: hsla(0, 0%, 100%, 0.9);
		z-index: 10000;
	}

	.container.footer-container {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.container-4 {
		max-width: 767px;
	}

	.container-4.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 1rem;
		padding-bottom: 1.5rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
		text-align: left;
	}

	.nav-menu {
		width: 100%;
		margin-top: 99px;
	}

	.main-nav-container {
		max-width: 728px;
	}

	.mobile-menu-arrow {
		background-size: 16px 16px;
	}

	.mobile-menu-arrow:hover {
		background-size: 16px 16px;
	}

	.main-nav-link {
		margin-right: 15px;
		margin-left: 15px;
	}

	.main-nav-link.w--current {
		margin-right: 0px;
		margin-left: 0px;
	}

	.navbar {
		padding-right: 10px;
		padding-left: 25px;
	}

	.header-left-wrapper {
		width: 85%;
		margin-bottom: 1rem;
	}

	.areas-of-focus-wrap {
		width: 150px;
	}

	.slider-arrow-2 {
		width: 50px;
	}

	.slider-title {
		padding-left: 2rem;
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.slider-text {
		padding-left: 2rem;
	}

	.cta-link {
		font-size: 1.15rem;
		line-height: 1.4rem;
		letter-spacing: -0.04rem;
	}

	.row-2-cb {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.cb-title-wrap {
		width: 100%;
		height: auto;
		padding-top: 1rem;
		padding-bottom: 1rem;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
	}

	.row-2-cb-header {
		text-align: center;
	}

	.slider-button {
		margin-left: 2rem;
	}

	.fb-embed-wrapper {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: auto;
		margin-right: auto;
		margin-left: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.fb-embed {
		width: 420px;
		text-align: center;
	}

	.container-row-2 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.video-wrapper {
		display: block;
		width: 89vw;
		min-height: 50vw;
		margin-right: auto;
		margin-bottom: 3rem;
		margin-left: auto;
	}

	.footer-heading {
		text-align: left;
	}

	.footer-nav-list {
		margin-top: 0.55rem;
		margin-left: 0rem;
	}

	.social-media-link-block {
		margin-right: 0.75rem;
	}

	.footer-nav-wrapper {
		width: 100%;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.footer-col-wrap {
		width: 60%;
		min-width: 200px;
		padding-right: 1rem;
	}

	.footer-col-wrap.footer-col-wrap-2 {
		padding-right: 0rem;
	}

	.footer-nav-item {
		margin-top: 0rem;
	}

	.social-outer-wrap {
		width: 100%;
	}

	.social-media-wrapper {
		margin-bottom: 0rem;
		float: none;
	}

	.footer-nav-list-link {
		margin-top: 0.25rem;
		margin-left: 0rem;
	}

	.footer-nav-list-link:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.ycamp-header-title {
		font-size: 1.2rem;
		line-height: 1.2rem;
	}

	.ycamp-title-wrap {
		margin-left: 0px;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.camp-name-wrap {
		margin-top: 0px;
		margin-left: 0rem;
	}

	.footer-grip-link {
		display: block;
		margin: 1.5rem 0px 0rem;
		float: none;
	}

	.signoff-left {
		width: 100%;
		margin-top: 0.5rem;
		text-align: center;
	}

	.signoff-links-wrap {
		float: none;
		text-align: center;
	}

	.copyright-paragraph {
		margin-right: 0.5rem;
		margin-bottom: 0.75rem;
		float: none;
		text-align: left;
	}

	.signoff-link {
		display: inline-block;
		margin-right: 0.75rem;
		margin-bottom: 0.5rem;
	}

	.signoff-section {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.copyright-wrap {
		float: none;
		text-align: center;
	}

	.rotator {
		height: auto;
	}

	.slideshow-image-wrap {
		height: 300px;
	}

	.rotator-section {
		padding-top: 100px;
	}

	.slide-caption-wrapper {
		padding-right: 2rem;
	}

	.slide-mask {
		font-family: inherit;
		background-color: transparent;
	}

	.container-row-3-top {
		display: block;
	}

	.inside-page-banner {
		display: none;
		height: 200px;
	}

	.inside-page-wrapper {
		padding-top: 100px;
	}

	.main-content-wrap.program-page {
		padding-top: 3rem;
	}

	.program-area-inside-page-banner {
		display: none;
		height: 200px;
	}

	.program-area-container-1 {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.program-area-intro-paragraph {
		font-size: 1.2rem;
		line-height: 1.9rem;
	}

	.card-title {
		text-align: center;
	}

	.program-page-inside-page-banner {
		display: none;
		height: 200px;
	}

	.anchor-link {
		margin-right: 0.3rem;
		margin-bottom: 0.25rem;
		margin-left: 0.3rem;
	}

	.anchor-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.right-side-col-program-page ul {
		font-size: 0.95rem;
		line-height: 1.45rem;
	}

	.right-side-col-program-page li {
		line-height: 1.45rem;
	}

	.camp-overview-div {
		padding-bottom: 1.5rem;
	}

	.back-to-top {
		width: 50px;
		height: 50px;
	}

	.logo-wrap {
		width: 90px;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.75rem;
		line-height: 2.35rem;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 2.1rem;
	}

	h4 {
		font-size: 1rem;
	}

	h5 {
		font-size: 0.94rem;
		line-height: 1.5rem;
	}

	h6 {
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	blockquote {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.container.cta-container {
		height: auto;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.container.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.container.breadcrumb-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.container.anchor-link-container {
		padding-right: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
	}

	.container-4 {
		max-width: 479px;
	}

	.container-4.signoff-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0rem;
		padding: 0.5rem 0rem 1rem;
	}

	.nav-menu {
		margin-top: 92px;
	}

	.main-nav-container {
		max-width: none;
	}

	.mobile-menu-icon {
		font-size: 2.5rem;
	}

	.menu-button {
		margin-left: 0rem;
		padding-right: 10px;
	}

	.utilities {
		display: none;
	}

	.mobile-link-label {
		font-size: 0.9rem;
		line-height: 1.2rem;
	}

	.mobile-menu-arrow {
		width: 20%;
	}

	.main-nav-link {
		margin-right: 10px;
		margin-left: 10px;
		padding-left: 1rem;
		font-size: 1rem;
		letter-spacing: 0.03rem;
	}

	.main-nav-link:hover {
		background-color: #074975;
		color: #fff;
	}

	.main-nav-link.w--current {
		font-size: 0.9rem;
		letter-spacing: 0rem;
	}

	.main-nav-link.mobile-duplicate {
		width: 83%;
		font-size: 0.9rem;
		letter-spacing: 0rem;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: rgba(35, 31, 32, 0.2);
		color: #fff;
	}

	.menu-button-text {
		display: none;
		font-size: 0.7rem;
	}

	.navbar {
		padding-right: 0px;
		padding-left: 0.75rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.header-left-wrapper {
		width: 80%;
	}

	.areas-of-focus-wrap {
		width: 125px;
	}

	.slider-arrow-2 {
		top: 160px;
		height: 40px;
	}

	.slider-title {
		padding-left: 1.5rem;
		font-size: 1.9rem;
		line-height: 2.5rem;
	}

	.slider-text {
		padding-left: 1.5rem;
	}

	.slider-indicators {
		font-size: 0.65rem;
	}

	.cta-link {
		width: 100%;
		height: 55px;
		border-bottom: 1px solid #0060af;
		border-right-style: none;
	}

	.row-2-cb {
		height: 285px;
		margin-bottom: 0rem;
		border-radius: 0px;
	}

	.row-2-cb:hover {
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
	}

	.row-2-cb.cb-image-1 {
		border-radius: 0px;
	}

	.row-2-cb.cb-image-2 {
		margin-bottom: 0rem;
	}

	.row-2-cb.program-area-cb-image-1 {
		margin-bottom: 1rem;
		border-radius: 0px;
	}

	.row-2-cb.program-area-cb-image-2 {
		margin-bottom: 1rem;
	}

	.row-2-cb.program-area-cb-image-3 {
		margin-bottom: 1rem;
	}

	.row-2-cb.program-area-cb-image-4 {
		margin-bottom: 1rem;
		border-radius: 0px;
	}

	.row-2-cb.program-area-cb-image-5 {
		margin-bottom: 1rem;
	}

	.cb-title-wrap {
		border-top-left-radius: 0px;
		border-top-right-radius: 0px;
	}

	.row-2-cb-header {
		font-size: 1.3rem;
	}

	.slider-button {
		display: block;
		margin-left: 1.5rem;
	}

	.fb-embed-wrapper {
		display: block;
		width: 82vw;
	}

	.fb-embed {
		width: 286px;
	}

	.container-row-2 {
		padding: 0rem;
	}

	.video-wrapper {
		display: block;
		width: 82vw;
		min-height: 46vw;
		margin-right: auto;
		margin-left: auto;
	}

	.footer-nav-list {
		margin-bottom: 0px;
		margin-left: 0rem;
	}

	.footer-col-wrap {
		width: 100%;
		min-width: 0px;
		margin-top: 0rem;
		margin-bottom: 1.5rem;
		padding-right: 0rem;
		padding-bottom: 1.5rem;
		border-bottom: 1px solid #636466;
	}

	.footer-col-wrap.last-col {
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
		border-top-style: none;
		border-bottom-style: none;
	}

	.footer-col-wrap.footer-col-wrap-3 {
		margin-top: 0rem;
		padding-top: 0rem;
		border-top-style: none;
	}

	.social-outer-wrap {
		margin-top: 0rem;
	}

	.social-media-wrapper {
		margin-bottom: 0rem;
	}

	.footer-nav-list-link {
		margin-left: 0rem;
	}

	.ycamp-header-title {
		font-size: 1rem;
		line-height: 1.1rem;
	}

	.footer-grip-link {
		display: block;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
	}

	.signoff-links-wrap {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.copyright-paragraph {
		margin-right: 0.25rem;
		margin-bottom: 0rem;
		text-align: center;
	}

	.signoff-link {
		display: inline-block;
	}

	.signoff-section {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	._2x-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.slideshow-image-wrap {
		height: 180px;
	}

	.rotator-section {
		padding-top: 92px;
	}

	.slide-arrow-icon {
		font-size: 1.2rem;
	}

	.slide-caption-wrapper {
		padding-right: 1rem;
	}

	.home-section-outer-wrap {
		background-image: none;
	}

	.container-row-3-top {
		display: none;
	}

	.inside-page-banner {
		display: none;
		height: 150px;
	}

	.inside-page-wrapper {
		padding-top: 92px;
	}

	.content-outer-wrap {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.main-content-wrap {
		padding-top: 1.5rem;
	}

	.main-content-wrap.program-page {
		padding-top: 2.5rem;
	}

	.block-quote {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.program-area-inside-page-banner {
		display: none;
		height: 150px;
	}

	.program-area-container-1 {
		padding: 0rem;
	}

	.card-title {
		font-size: 1.3rem;
	}

	.program-page-inside-page-banner {
		display: none;
		height: 150px;
	}

	.anchor-link {
		margin-bottom: 0.15rem;
		font-size: 0.85rem;
		letter-spacing: 0.1rem;
	}

	.anchor-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.camp-overview-div {
		padding-bottom: 0.5rem;
	}

	.specialty-camp-1 {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.specialty-camp-2 {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.specialty-camp-3 {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.specialty-camp-4 {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.specialty-camp-5 {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.specialty-camp-6.last {
		padding-top: 0rem;
	}

	.ranch-camp-1 {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.ranch-camp-2 {
		padding-top: 0rem;
		padding-bottom: 1rem;
	}

	.ranch-camp-3.last {
		padding-top: 0rem;
	}

	.back-to-top {
		padding-bottom: 1rem;
		border-radius: 50%;
	}

	.brandlink {
		margin-right: 0rem;
		padding-left: 0px;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}

	.logo-wrap {
		width: 80px;
		margin-right: 0.8rem;
		-webkit-box-align: start;
		-webkit-align-items: flex-start;
		-ms-flex-align: start;
		align-items: flex-start;
	}
}

@font-face {
	font-family: 'Fa solid 900';
	src: url('/20150810114905/assets/fonts/fa-solid-900.woff2') format('woff2'), url('/20150810114905/assets/fonts/fa-solid-900.eot') format('embedded-opentype'), url('/20150810114905/assets/fonts/fa-solid-900.woff') format('woff'), url('/20150810114905/assets/fonts/fa-solid-900.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

/*************** Webflow Styles **********************/
/****************************************************/
/* FONT SMOOTHING */
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}
/* CONTROL PHONE NUMBER COLOR */

/* CONTROL CURSOR CLICK & DRAG HIGHLIGHT COLOR */
/* Mozilla-based browsers */
/* ADJUST SLIDESHOW HEIGHT ON LAPTOPS */
@media screen and (min-width: 992px) and (max-height: 828px) {
	.rotator {
		height: 65vh !important;
	}
}
/* COLLAPSIBLE STICKY HEADER  */
@media screen and (min-width: 992px) {
	/* Desktop */
	.sticky .navbar {
		height: auto;
	}

	.sticky .brandlink {
		margin-top: 1rem;
	}

	.sticky .logo-wrap {
		width: 90px;
	}

	.sticky .camp-name-wrap {
		margin-top: 0px;
	}

	.sticky .ycamp-header-title {
		font-size: 1rem;
	}

	.sticky .ycamp-title-wrap {
		margin-bottom: 0.125rem;
	}

	.sticky .areas-of-focus-wrap {
		width: 160px;
	}

	.sticky .utilities {
		margin-top: 0.65rem;
	}

	.sticky .utilities-link {
		color: #0060af;
		cursor: pointer;
	}

	.sticky .main-nav-link {
		padding: 0.5rem 0.75rem;
		color: #0060af;
	}

	.sticky .main-nav-link:hover,
	.sticky .utilities-link:hover {
		color: #fff;
	}

	.sticky.header-section {
		background-color: rgba(255, 255, 255, 0.9);
		position: fixed;
	}
}

@media screen and (max-width: 991px)and (min-width: 768px) {
	/* Tablet */
	.sticky .brandlink {
		margin-top: 1rem;
	}

	.sticky .logo-wrap {
		width: 90px;
	}

	.sticky .camp-name-wrap {
		margin-top: 0px;
	}

	.sticky .ycamp-header-title {
		font-size: 1rem;
	}

	.sticky .ycamp-title-wrap {
		margin-bottom: 0.125rem;
	}

	.sticky .areas-of-focus-wrap {
		width: 160px;
	}

	.sticky.header-section {
		background-color: rgba(255, 255, 255, 0.9);
	}
}
/* MODIFICATIONS ON NARROWER DESKTOPS */
@media screen and (max-width: 1280px) and (min-width: 992px) {
	/* Narrower Desktop */
	.main-nav-link {
		padding-left: 0.6rem;
		padding-right: 0.6rem;
		font-size: 0.93rem;
	}

	.main-nav-dd-widget {
		margin-left: 1vw;
	}
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
	/* Narrower Desktop */
	.logo-wrap {
		width: 110px;
	}

	.ycamp-header-title {
		font-size: 1.3rem;
	}

	.navbar {
		height: 126px;
	}

	.rotator-section {
		padding-top: 126px;
	}

	.inside-page-wrapper {
		padding-top: 126px;
	}

	.camp-name-wrap {
		margin-top: 0px;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

.utilities-link.active {
	background-color: #0077c8;
	color: #fff;
}

/*********************** Header **********************/
/*****************************************************/
.utilities .utilities-link:last-child {
	margin-right: 0px;
}

/****************** Accesible Menu *******************/
/*****************************************************/
ul.main-nav-container {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

ul.main-nav-container li.main-nav-dd-widget {
	padding: 0px;
	margin-top: 0px;
	position: relative;
}

ul.main-nav-container li.main-nav-dd-widget .main-nav-link.active {
	background-color: #0077c8;
	color: #fff;
}

.accesible-navigation-menu li .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	min-width: 150px;
	left: 0;
	padding-top: 7px;
	transition: max-height 400ms ease 0s, opacity 500ms ease 0s;
	height: 0;
	border-radius: 10px;
	background-color: #fff;
	box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.2);
	color: #fff;
}

.accesible-navigation-menu li .container-wrapper ul {
	display: none;
	margin-top: 0px;
	padding: 0.25rem 0.5rem;
	margin-bottom: 0px;
	width: 100%;
}

.accesible-navigation-menu li .container-wrapper ul li {
	display: inline-block;
	width: 100%;
	text-align: left;
	min-width: 170px;
}

.accesible-navigation-menu li .container-wrapper ul li a {
	display: block;
	width: 100%;
	margin-bottom: 2px;
	padding: 0.5rem 0.75rem;
	border-radius: 8px;
	color: #231f20;
	font-size: 0.88rem;
	line-height: 1.1rem;
	font-weight: 400;
	text-decoration: none;
}

.accesible-navigation-menu li .container-wrapper ul li a:hover {
	border-radius: 10px;
	background-color: #0077c8;
	color: #fff;
}

.accesible-navigation-menu li.open .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.open .container-wrapper ul {
	display: inline-block;
}

/****************** Mobile Menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background: #0060af;
}

.mobile-navigation-menu li a.w-nav-link {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 700;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #fff;
	background-color: transparent;
}

.mobile-navigation-menu li a.w-nav-link:hover,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth):hover,
.mobile-navigation-menu li.active a.w-nav-link {
	background: rgba(35, 31, 32, 0.2) !important;
}

.mm-listitem:after {
	left: 0;
}

.mm-listitem,
.mm-listitem .mm-btn_next,
.mm-listitem .mm-btn_next:before,
.mm-listitem:after,
.mm-listview {
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-menu .mm-listview .mm-btn_next:after {
	border-color: #fff;
	right: 31px;
	border-width: 3px;
}

.mm-menu .mm-navbar {
	height: 50px;
	text-align: left;
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-menu .mm-navbar a {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1rem;
	line-height: 1.2rem;
	font-weight: 700;
	padding-top: 15px;
	background-color: transparent;
	color: #fff;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}

.mm-navbar__btn:first-child {
	left: -4px;
	top: 4px;
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
	border-width: 3px;
}

.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
.mm-menu .mm-listitem_selected > span {
	background: none;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
	width: 70px;
}

.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + a,
.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) + span {
	margin-right: 70px;
}

/******************** Homepage **********************/
/*****************************************************/
.video-wrapper {
	background: #fff;
	padding-bottom: 0px;
	position: relative;
}

.video-widget p:last-child {
	margin-bottom: 0px;
}

.video-wrapper iframe {
	position: absolute;
	width: calc(100% + 20px);
	height: 100%;
	min-height: 27vw;
	float: left;
	left: -10px;
	display: inline-block;
	top: 0;
}

.fb-embed {
	height: 100%;
}

@media (max-width: 991px) {
	.video-wrapper iframe {
		min-height: 32vw;
	}
}

@media (max-width: 767px) {
	.video-wrapper iframe {
		min-height: 50vw;
	}

	.container-row-3 {
		display: block;
	}
}

@media (max-width: 479px) {
	.video-wrapper iframe {
		min-height: 46vw;
	}
}


/********************** Slideshow *************************/
/**********************************************************/
.slider-more,
.slider-more:hover {
	display: inline-block;
	width: 100%;
	height: 100%;
	border: 0px;
	box-shadow: none;
	text-decoration: none;
	transition: none;
	padding: 0px;
}

.slider-paragraph p:not(.slideshowbutton) {
	display: inline-block;
	padding: 6px 1rem 6px 3rem;
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	background-color: rgba(0, 107, 107, 0.85);
	color: #fff;
	font-size: 1.25rem;
	line-height: 2rem;
	font-weight: 400;
}


.slider-title,
.slider-paragraph p:not(.slideshowbutton),
.slideshowbutton {
	opacity: 0;
	-webkit-transform: translate(-300px, 0px);
	-ms-transform: translate(-300px, 0px);
	transform: translate(-300px, 0px);
}

.cycle-slide-active .slider-title {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 500ms ease 0s, transform 800ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

.cycle-slide-active .slider-paragraph p:not(.slideshowbutton) {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 400ms ease 400ms, transform 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 300ms;
}

.cycle-slide-active .slideshowbutton {
	opacity: 1;
	transform: translateX(0px) translateY(0px) translateZ(0px);
	transition: opacity 400ms ease 400ms, transform 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 500ms;
}


@media (max-width: 991px) {
	.w-slide,
	.w-slider-mask {
		height: auto;
	}

	.slider-more,
	.slider-more:hover {
		height: auto;
	}

	.slider-paragraph p:not(.slideshowbutton) {
		padding-top: 8px;
		padding-bottom: 8px;
		font-size: 1.125rem;
		line-height: 1.75rem;
	}
}


@media (max-width: 767px) {
	.slider-paragraph p:not(.slideshowbutton) {
		padding-left: 2rem;
	}

	.slideshowButton a,
	.slideshowbutton a {
		margin-left: 2rem !important;
	}
}

@media (max-width: 479px) {
	.slider-paragraph p:not(.slideshowbutton) {
		padding-left: 1.5rem;
	}

	.slideshowButton a,
	.slideshowbutton a {
		display: block !important;
		margin-left: 1.5rem !important;
	}
}

/****************** Inside Page *******************/
/*************************************************/
.main-content *:first-child {
	margin-top: 0px;
}

.left-nav-section-title a,
.left-nav-section-title a:hover {
	color: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	border-bottom: 0px;
}

.breadcrumb-divider {
	float: left;
}

.breadcrumbs-list li:last-child a,
.breadcrumbs-list li:last-child a:hover {
	border-bottom: 0px;
}

.left-nav-list-item {
	margin-bottom: 0px;
}

.left-nav-list-link {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
}

.left-nav-list-link:hover {
	background-position: 0px 14px;
}

.left-nav-list-link.active,
.left-nav-list-link.active:hover {
	color: #006eb8;
	background-image: url("/20150810114905/assets/images/chevron-right_0077C8.svg");
	background-size: 7px 12px;
	background-repeat: no-repeat;
	background-color: #e5f1f9;
	background-position: 8px 50%;
	padding-right: 0.25rem;
	padding-left: 1.25rem;
	transform: none;
}

.left-nav-list-link.grandchild-link.active {
	background-size: 6px 12px;
}

.left-nav-list-link.greatgrandchild-link.active {
	background-size: 5px 10px;
}

.intro-paragraph,
.intro_paragraph {
	font-size: 1.3rem;
	line-height: 2rem;
	font-weight: 400;
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	margin-bottom: 1.25rem;
}

img {
	height: auto;
	max-width: 100%;
	display: inline-block;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}

	.intro-paragraph,
	.intro_paragraph {
		font-size: 1.2rem;
		line-height: 1.9rem;
	}
}

/******************* Right Sidebar *******************/
/*****************************************************/
.right-side-col-program-page .box {
	margin-bottom: 2rem;
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col-program-page .box:before {
	width: 50px;
	height: 4px;
	margin-top: 0.5rem;
	background-color: #01a490;
	content: "";
	display: block;
	margin-bottom: 1.25rem;
}

.right-side-col-program-page .box h4,
.right-side-col-program-page .box h3 {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	color: #0077c8;
	font-size: 2rem;
	font-size: 1.2rem;
	line-height: 1.7rem;
}

.right-side-col-program-page .box p {
	font-size: 0.95rem;
	line-height: 1.45rem;
}

.right-side-col-program-page .box ul {
	font-size: 0.95rem;
	line-height: 1.45rem;
}


.right-side-col-program-page .box td {
	vertical-align: top;
	padding-bottom: 0.5rem;
}

.right-side-col-program-page .box .contentbox_item_image {
	width: 24px;
}

.right-side-col-program-page .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col-program-page .box.links p {
	margin-bottom: 0.25rem;
	margin-top: 0px;
}

.right-side-col-program-page.box.tools td:first-child img {
	margin-right: 5px;
}


/*********************** Footer **********************/
/*****************************************************/
.phone-link.desktop {
	display: inline;
}

.phone-link.mobile {
	display: none;
}

#GRIPFooterLogo {
	line-height: 13px;
	padding-top: 0px !important;
	margin-top: 0.8rem;
	float: right;
}

#GRIPFooterLogo a {
	background-color: transparent;
	font-family: inherit;
}

.footer-nav-list-link.active {
	background-image: url("/20150810114905/assets/images/chevron-right_0077C8.svg");
	background-size: 7px 12px;
	color: #0077c8;
}

@media (max-width: 991px) {
	.phone-link.desktop {
		display: none;
	}

	.phone-link.mobile {
		display: inline;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		float: none;
		margin-left: auto;
		margin-right: auto;
		margin-top: 1rem;
	}
}


/****************** Inside Page Styles ***************/
/*****************************************************/
/*skip to main content WCAG link*/
.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0 !important;
	border: 0px !important;
	line-height: 0;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	padding: 0.6rem 1rem !important;
	border: 2px solid #0077c8 !important;
	line-height: 1.1rem;
	box-shadow: 0 0 5px #000;
	z-index: 5000;
}

/* heading links */
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	border-bottom: 1px dashed #a0a0a0;
	box-shadow: none;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #0077c8;
	text-decoration: none;
	font-family: inherit;
	line-height: inherit;
	font-size: inherit;
	font-weight: inherit;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -1px 0 0 transparent;
	color: #0060af;
	text-decoration: none;
}

/* links */
.left-sidebar a,
.main-content a:not(.row-2-cb),
.right-side-col-program-page a,
.homebox a {
	padding-bottom: 3px;
	border-bottom: 1px dashed #a0a0a0;
	box-shadow: none;
	-webkit-transition-duration: 350ms;
	transition-duration: 350ms;
	color: #0077c8;
	text-decoration: none;
}

.left-sidebar a:hover,
.main-content a:not(.row-2-cb):hover,
.right-side-col-program-page a:hover,
.homebox a:hover {
	border-bottom-color: transparent;
	box-shadow: inset 0 -1px 0 0 transparent;
	color: #0060af;
}

/* buttons */
.main-content-wrap .button,
.main-content-wrap .primary,
.home-section-outer-wrap .primary,
.cms_form_button.primary,
button[type="submit"],
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.main-content-wrap .secondary,
.home-section-outer-wrap .secondary,
.cms_form_button.secondary,
.main-content-wrap .tertiary,
.home-section-outer-wrap .tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button {
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 0.6rem 1rem !important;
	border: 2px solid #0077c8 !important;
	border-radius: 8px;
	background-color: #0077c8;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff !important;
	font-size: 0.95rem;
	line-height: 1.1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.03rem;
	text-decoration: none;
	text-transform: uppercase;
	cursor: pointer;
	box-shadow: none;
}

.main-content-wrap .button:hover,
.main-content-wrap .primary:hover,
.home-section-outer-wrap .primary:hover,
button[type="submit"]:hover,
.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
.main-content-wrap .secondary:hover,
.home-section-outer-wrap .secondary:hover,
.cms_form_button.secondary:hover,
.main-content-wrap .tertiary,
.home-section-outer-wrap .tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover {
	border-width: 2px;
	border-color: #0077c8;
	background-color: #fff;
	box-shadow: none;
	color: #0077c8 !important;
	box-shadow: none;
}

.main-content-wrap .secondary,
.home-section-outer-wrap .secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	border-color: #636466 !important;
	background-color: #636466;
	color: #fff !important;
}


.main-content-wrap .secondary:hover,
.home-section-outer-wrap .secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	background-color: #fff;
	color: #636466 !important;
	border-color: #636466 !important;
}

.main-content-wrap .tertiary,
.home-section-outer-wrap .tertiary,
.cms_form_button.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	padding: 0.4rem 0.5rem !important;
	border: 2px solid #231f20 !important;
	background-color: #231f20;
	font-size: 0.85rem;
	line-height: 1rem;
	color: #fff !important;
}

.main-content-wrap .tertiary:hover,
.home-section-outer-wrap .tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover {
	border: 2px solid #231f20 !important;
	background-color: #fff;
	color: #231f20 !important;
}


/* messages */
#message.success {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #1ba53c;
	background-color: rgba(27, 165, 60, 0.2);
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	font-size: 1rem;
	line-height: 1.5rem;
}

#message.error {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	border-left: 6px solid #e70417;
	background-color: rgba(253, 54, 54, 0.15);
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	font-size: 1rem;
	line-height: 1.5rem;
}

#message.success *,
#message.error * {
	font-family: Verdana, Geneva, sans-serif;
	color: #231f20;
	font-size: 1rem;
	line-height: 1.5rem;
}

#message.success p:last-child,
#message.error p:last-child {
	margin-bottom: 0px;
}

/*tables*/
table.styled {
	background-color: white;
}

table.styled th,
table.styled td {
	border-right: 1px solid rgba(99, 100, 102, 0.5);
	vertical-align: top;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	font-size: 1.1rem;
	line-height: 1.4rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #0077c8;
	color: #fff;
}

table.styled th {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #c4c4c4;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	box-shadow: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #e5e5e5;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.main-content-wrap input[type="text"],
.main-content-wrap input[type="tel"],
.main-content-wrap input[type="password"],
.main-content-wrap input[type="email"],
.main-content-wrap select,
.main-content-wrap textarea {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.main-content-wrap textarea {
	resize: vertical;
}

.main-content-wrap label,
.main-content-wrap .cms_label {
	font-size: 0.9rem;
	line-height: 1.4rem;
	font-weight: 600;
}

@media (max-width: 991px) {
	.main-content-wrap input[type="text"],
	.main-content-wrap input[type="tel"],
	.main-content-wrap input[type="password"],
	.main-content-wrap input[type="email"],
	.main-content-wrap select,
	.main-content-wrap textarea {
		width: 100%;
	}
}

/*blockquotes*/
blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/*horizontal ruler*/
hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(99, 100, 102, 0.5);
	border: 0px;
	color: rgba(99, 100, 102, 0.5);
	border-top: 1px solid;
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

/******************** Modules **********************/
/***************************************************/
/* Events */
#events_module.cms_list #categories label {
	display: inline-block;
}

#events_module .cms_field .cms_td_answers label {
	font-weight: normal;
}

#events_module.cms_list .events-filters .viewAllEvents {
	clear: both;
	display: block;
	bottom: 0;
}

#events_module.cms_list .events-filters .filter {
	width: 100%;
}

#events_module.cms_list .events-filters .filter select {
	width: 100% !important;
}

#events_module.cms_form input[type="text"] {
	max-width: 300px;
	width: 100% !important;
}

#events_module.cms_form .cms_required,
#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field {
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form input[type="text"] {
		max-width: 300px;
		width: 100% !important;
	}

	#events_module.cms_list .events-filters .filter {
		float: none;
	}
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(99, 100, 102, 0.5);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form .cms_td_answers label {
	font-weight: normal;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	color: #4d4d4d !important;
}

#events_module.cms_list .events-filters {
	background-color: #e5f1f9;
}

#events_module.cms_list .events-filters label {
	color: #006eb8;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #e5f1f9;
	float: none;
	display: inline-block;
}

.select2-container .select2-search--inline {
	float: none;
	display: inline-block;
}

#events_module.cms_list #categories.filter .select2-container .select2-search--inline {
	margin-bottom: 0px;
}

#events_module.cms_list div.events_module_calendar .fc-view-container table tr th,
#events_module.cms_list .cms_td_header,
#events_module.cms_list .fc-axis.fc-widget-content {
	background: #006eb8;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
	white-space: normal;
	display: block;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	width: calc(100% - 10px);
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}


/* FAQ */
#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	border-top: 1px solid rgba(99, 100, 102, 0.5);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
	margin-bottom: 1.5rem;
}

/* News */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	border-top: 1px solid rgba(99, 100, 102, 0.5);
	margin-bottom: 0.8rem;
	margin-top: 1.5rem;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
	}
}

/* Partners */
#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(99, 100, 102, 0.5);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* Photo Albums */
#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(99, 100, 102, 0.5);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_entity #carousel-slideshow .cycle-carousel-prev,
#photoalbums_module.cms_entity #carousel-slideshow .cycle-carousel-next {
	overflow: hidden;
	height: 92px;
}

#photoalbums_module.cms_entity #carousel-slideshow {
	position: relative;
}


@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}


/* Resources */

/* Staff Directory */
#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(99, 100, 102, 0.5);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 150px;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* Testmnials */
#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	border-top: 1px solid rgba(99, 100, 102, 0.5);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}

/* Submission Forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	font-weight: normal;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	background-color: #fff;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	background-color: #fff;
	font-family: inherit;
}

/* Accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 300px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #cee2ee;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #3169b4;
	font-size: 1.4rem;
	line-height: 1.9rem;
	font-weight: 700;
	font-family: 'Open Sans', sans-serif;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ff0000;
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

/***************** Content Boxes *******************/
/***************************************************/
/* events */
#events_module.homepage_contentbox .event_list {
	margin-bottom: 15px;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
}

/* news */
#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 17px;
}

#news_module.homepage_contentbox .cms_list_item .cms_title h3 {
	margin: 0px 0px 12px 0px;
	font-size: 1.2rem;
	line-height: 1.5rem;
}

#news_module.homepage_contentbox .cms_list_item .cms_date h3 {
	margin: 0px;
	font-size: 1rem;
	line-height: 1.3em;
}

/* partners */
#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
	margin-bottom: 15px;
}

/* testimonials */
#testimonials_module_contentbox .author-name,
#testimonials_module_contentbox .company {
	font-weight: bold;
}

#testimonials_module_contentbox .image-box {
	margin-bottom: 7px;
}

/* forms */
#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}


#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox .radio_wrapper .cms_table_fullwidth label {
	font-weight: normal;
}

.accredited-logos {
	display: flex;
}
